.header {
  text-align: center;
}

.logoPablo {
  height: 100px;
  align-items: center !important;
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.nota {
  color: #fff;
  font-style: normal !important;
  font-size: 25px;
  text-align: center;
  padding: 5px;
}

.pergunta {
  color: #fff;
  font-style: bold;
  font-size: 20px;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.centerImg {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.painel {
  border-radius: 25px;
}

.alternativa {
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 10px;
  border: 10px;
}

.alternativasRadius {
  padding-left: 10px;
}

.btnEnviarRespostasIP {
  background: #e20000;
  border-color: #e20000;
  font-weight: bold;
  border-radius: 12px;
}

.inputEmail {
  margin: 15px;
}

.inputCodigo {
  margin: 15px;
  margin-left: 5px;
}