.header {
  text-align: center;
}

.logoPablo {
  height: 100px;
  align-items: center !important;
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.logoTribo {
  height: 120px;
  align-items: center !important;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  margin-bottom: -10px;
}

.nota {
  color: #fff;
  font-family: 'Arial, Helvetica, sans-serif';
  font-style: italic;
  font-size: 25px;
  text-align: center;
  padding: 5px;
}

.notaTribo {
  color: #fff;
  font-family: 'Arial, Helvetica, sans-serif';
  font-style: italic;
  font-size: 20px;
  text-align: center;
  padding: 5px;
}

.pergunta {
  color: #fff;
  font-style: bold;
  font-size: 20px;
}

.perguntaGeneral {
  color: #fff;
  font-style: bold;
  font-size: 16px;
  text-align: center;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.centerImg {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.painel {
  border-radius: 25px;
}

.alternativa {
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 10px;
  border: 10px;
}

.alternativasRadius {
  padding-left: 10px;
}

.btnEnviarRespostasProva {
  background: #f1ce63;
  border-color: #f1ce63;
  font-weight: bold;
  border-radius: 12px;
}

.inputEmail {
  margin: 15px;
}

.inputCodigo {
  margin: 15px;
  margin-left: 5px;
}

.estados {
  width:  100%;
  margin: 15px;
  height: 40px;
  color: #495057;
  border-radius: 5px;
}

.linhas {
  white-space: nowrap;
}

.tituloGenerais {
  font-size: 38px !important;
  margin-top: -20px;
  color: #00c354 !important;
  text-align: center;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-10 {
  font-size: 10px;
}

.espacoCartao {
  margin-bottom: 10px;
}