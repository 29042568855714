.header {
  text-align: center;
}

.logoRotaDigital {
  height: 50px;
  align-items: center !important;
  display: flex;
  justify-content: center;
}

.nota {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  font-style: normal !important;
  font-size: 20px;
  text-align: center;
  padding: 5px;
}

.pergunta {
  font-family: Poppins;
  color: #fff;
  font-style: bold;
  font-size: 20px;
  justify-content: center;
  text-align: center;
  align-items: center;

}

.center {
  font-family: Poppins;
  align-items: center;
  display: flex;
  justify-content: center;
}

.centerImg {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.painel {
  border-radius: 25px;
}
.painelresultado {
  padding-top: 10px;
}
.profissao {
  font-size: 35px;
  justify-content: center;
  align-items: center;

}

.alternativa {
  font-family: Poppins;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 10px;
  border: 10px;
}

.alternativasRadius {
  font-family: Poppins;
  padding-left: 10px;
}

.btnEnviarRespostas {
  background: #FF006E;
  border-color: #FF006E;
  font-weight: bold;
  border-radius: 12px;
  height: 60px;
}

.btnEnviarRespostasWhats {
  background: #25D366;
  border-color: #25D366;
  font-weight: bold;
  border-radius: 12px;
  height: 60px;
}

.btnEnviarRespostasTelegram {
  background: #FFD37D;
  border-color: #FFD37D;
  color: #1a1a1a;
  font-weight: bold;
  border-radius: 12px;
  height: 60px;
}

.inputEmail {
  margin: 15px;
}

.inputCodigo {
  margin: 15px;
  margin-left: 5px;
}

.tituloResultado {
  font-size: 30px;
  font-family: Poppins;

}

.perfil {
  font-size: 30px;
  font-weight: bold;
  color: #e60000;
  font-family: Poppins;

}

.descricaoResultado {
  font-size: 16px;
  font-family: Poppins;

}

.logoBotaoWhats {
  height: 18px;
}

.italicoSublinhado {
  font-style: italic;
  text-decoration: underline;
}

.tituloUm {
  color: #ffffff;
  font-size: 30px;
  font-family: Poppins;

}

.tituloDois {
  color: #ffffff;
  font-size: 20px;
  font-family: Poppins;
}